// src/pages/CreateSkinPage.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styled from '@emotion/styled';
import PaymentModal from '../components/PaymentModal';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
`;

const CreateSkinPage: React.FC = () => {
    const [entityType, setEntityType] = useState('');
    const [requirements, setRequirements] = useState('');
    const [resolution, setResolution] = useState('64x64');
    const [isModalOpen, setModalOpen] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setModalOpen(true);
    };

    const handlePay = () => {
        console.log('Payment Successful, create skin...');
        setModalOpen(false);
    };

    return (
        <>
            <Header />
            <main>
                <Form onSubmit={handleSubmit}>
                    <select value={entityType} onChange={(e) => setEntityType(e.target.value)}>
                        <option value="">Select Entity</option>
                        <option value="ender_dragon">Ender Dragon</option>
                        <option value="zombie">Zombie</option>
                        {/* Add more options as needed */}
                    </select>
                    <textarea placeholder="Design requirements..." value={requirements} onChange={(e) => setRequirements(e.target.value)} />
                    <select value={resolution} onChange={(e) => setResolution(e.target.value)}>
                        <option value="64x64">64x64</option>
                        <option value="128x128">128x128</option>
                        {/* Add more options as needed */}
                    </select>
                    <button type="submit">Generate Skin</button>
                </Form>
                <PaymentModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} onPay={handlePay} />
            </main>
            <Footer />
        </>
    );
};

export default CreateSkinPage;
