import React from 'react';
import styled from '@emotion/styled';

interface FilterProps {
    entityType: string;
    resolution: string;
    setEntityType: (value: string) => void;
    setResolution: (value: string) => void;
}

const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const FilterComponent: React.FC<FilterProps> = ({ entityType, resolution, setEntityType, setResolution }) => {
    return (
        <FilterContainer>
            <select value={entityType} onChange={(e) => setEntityType(e.target.value)}>
                <option value="">All Entities</option>
                <option value="ender_dragon">Ender Dragon</option>
                <option value="zombie">Zombie</option>
                {/* Add more options as needed */}
            </select>
            <select value={resolution} onChange={(e) => setResolution(e.target.value)}>
                <option value="">All Resolutions</option>
                <option value="64x64">64x64</option>
                <option value="128x128">128x128</option>
                {/* Add more options as needed */}
            </select>
        </FilterContainer>
    );
};

export default FilterComponent;
