import React from 'react';
import styled from '@emotion/styled';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f7f7f7;
  color: #333;
  position: fixed;
  width: 100%;
  bottom: 0;
`;

const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <p>© 2024 SkinForge. All rights reserved.</p>
        </FooterContainer>
    );
};

export default Footer;
