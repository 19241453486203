// src/pages/ProfileSettingsPage.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styled from '@emotion/styled';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
`;

const ProfileSettingsPage: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Profile updated:', { name, email, password });
    };

    return (
        <>
            <Header />
            <main>
                <Form onSubmit={handleSave}>
                    <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <button type="submit">Save</button>
                </Form>
            </main>
            <Footer />
        </>
    );
};

export default ProfileSettingsPage;
