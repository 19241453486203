import React from 'react';
import { Link } from 'react-router-dom';
import styled from "@emotion/styled";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #007BFF;
  color: white;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;
`;

const Header: React.FC = () => {
    return (
        <HeaderContainer>
            <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                <h1>SkinForge</h1>
            </Link>
            <NavLinks>
                <Link to="/my-skins" style={{ color: 'white', textDecoration: 'none' }}>My Skins</Link>
                <Link to="/create-skin" style={{ color: 'white', textDecoration: 'none' }}>Create Skin</Link>
                <Link to="/profile" style={{ color: 'white', textDecoration: 'none' }}>Profile</Link>
            </NavLinks>
        </HeaderContainer>
    );
};

export default Header;
