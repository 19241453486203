// src/hooks/useFetchSkins.ts
import { useState, useCallback } from 'react';
import axios from 'axios';
import { Skin } from '../types';

const useFetchSkins = () => {
    const [skins, setSkins] = useState<Skin[]>([]);

    const fetchSkins = useCallback(async (searchTerm: string, entityType: string, resolution: string) => {
        try {
            const response = await axios.get('/api/skins', {
                params: { searchTerm, entityType, resolution }
            });
            setSkins(response.data);
        } catch (error) {
            console.error('Error fetching skins:', error);
        }
    }, []);

    return { skins, fetchSkins };
};

export default useFetchSkins;
