import React, { ChangeEvent } from 'react';
import styled from '@emotion/styled';

interface SearchBarProps {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange }) => {
    return <SearchInput type="text" placeholder="Search skins..." value={value} onChange={onChange} />;
};

export default SearchBar;
