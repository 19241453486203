// src/pages/MySkinsPage.tsx
import React, {useState, useEffect, useCallback} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import FilterComponent from '../components/FilterComponent';
import SkinCard from '../components/SkinCard';
import styled from '@emotion/styled';
import useFetchSkins from '../hooks/useFetchSkins';

const SkinsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
`;

const MySkinsPage: React.FC = () => {
    const { skins, fetchSkins } = useFetchSkins();
    const [searchTerm, setSearchTerm] = useState('');
    const [entityType, setEntityType] = useState('');
    const [resolution, setResolution] = useState('');

    const fetchSkinsCallback = useCallback(() => {
        fetchSkins(searchTerm, entityType, resolution);
    }, [fetchSkins, searchTerm, entityType, resolution]);

    useEffect(() => {
        fetchSkinsCallback();
    }, [fetchSkinsCallback]);

    return (
        <>
            <Header />
            <main>
                <SearchBar value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                <FilterComponent
                    entityType={entityType}
                    resolution={resolution}
                    setEntityType={setEntityType}
                    setResolution={setResolution}
                />
                <SkinsGrid>
                    {skins.map((skin) => (
                        <SkinCard key={skin.id} skin={skin} onClick={() => console.log('Open Skin Viewer')} />
                    ))}
                </SkinsGrid>
            </main>
            <Footer />
        </>
    );
};

export default MySkinsPage;
