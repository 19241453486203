// src/components/PaymentModal.tsx
import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

interface PaymentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onPay: () => void;
}

const ModalBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 300px;
`;

const PaymentModal: React.FC<PaymentModalProps> = ({ isOpen, onClose, onPay }) => {
    return isOpen ? (
        <ModalBackdrop initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={onClose}>
            <ModalContent initial={{ scale: 0.8 }} animate={{ scale: 1 }} exit={{ scale: 0.8 }} onClick={(e) => e.stopPropagation()}>
                <h2>Complete Payment</h2>
                <button onClick={onPay}>Pay with Google Pay</button>
            </ModalContent>
        </ModalBackdrop>
    ) : null;
};

export default PaymentModal;
