// src/components/SkinCard.tsx
import React from 'react';
import styled from '@emotion/styled';
import { Skin } from '../types';

interface SkinCardProps {
    skin: Skin;
    onClick: () => void;
}

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SkinCard: React.FC<SkinCardProps> = ({ skin, onClick }) => {
    return (
        <Card onClick={onClick}>
            <img src={skin.previewUrl} alt={skin.name} style={{ width: '100%', borderRadius: '8px' }} />
            <h3>{skin.name}</h3>
            <p>Created on: {new Date(skin.createdAt).toLocaleDateString()}</p>
        </Card>
    );
};

export default SkinCard;
