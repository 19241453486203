// src/pages/SkinViewerPage.tsx
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Skin3DViewer from '../components/Skin3DViewer';
import styled from '@emotion/styled';

const ViewerContainer = styled.div`
  padding: 2rem;
  text-align: center;
`;

const SkinViewerPage: React.FC = () => {
    const skinUrl = '/path/to/skin.png'; // Example URL

    return (
        <>
            <Header />
            <main>
                <ViewerContainer>
                    <h2>Skin Viewer</h2>
                    <Skin3DViewer skinUrl={skinUrl} />
                    <button onClick={() => console.log('Download Skin')}>Download</button>
                    <button onClick={() => console.log('Delete Skin')}>Delete</button>
                </ViewerContainer>
            </main>
            <Footer />
        </>
    );
};

export default SkinViewerPage;
