// src/pages/HomePage.tsx
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styled from '@emotion/styled';

const MainContent = styled.main`
  padding: 2rem;
  text-align: center;
`;

const HomePage: React.FC = () => {
    return (
        <>
            <Header />
            <MainContent>
                <h1>Welcome to SkinForge</h1>
                <p>Create and manage your Minecraft skins with ease!</p>
            </MainContent>
            <Footer />
        </>
    );
};

export default HomePage;
