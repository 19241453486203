// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MySkinsPage from './pages/MySkinsPage';
import CreateSkinPage from './pages/CreateSkinPage';
import ProfileSettingsPage from './pages/ProfileSettingsPage';
import SkinViewerPage from './pages/SkinViewerPage';

const App: React.FC = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/my-skins" element={<MySkinsPage />} />
          <Route path="/create-skin" element={<CreateSkinPage />} />
          <Route path="/profile" element={<ProfileSettingsPage />} />
          <Route path="/skin-viewer" element={<SkinViewerPage />} />
        </Routes>
      </Router>
  );
};

export default App;
